import { Alert, AlertProps, Button, Form, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { Transcription } from '@amzn/taprootinvoicemanagementservice-ts-client';

import { FormSection } from '../../common-components/formFields/FormSection';
import { InvoiceManualTranscriptionTopLevelFields } from './InvoiceManualTranscriptionTopLevelFields';
import { useHydrateInvoiceManualTranscriptionForm } from '../hooks/useHydrateInvoiceManualTranscriptionForm';
import { InvoiceManualTranscriptionMeterFields } from 'src/components/manifests/manual-transcription/InvoiceManualTranscriptionMeterLevelFields';
import { InvoiceManualTranscriptionUtilityFields } from 'src/components/manifests/manual-transcription/InvoiceManualTranscriptionUtilityLevelFields';

export interface InvoiceManualTranscriptionFormProps {
  transcription: Transcription;
  invoiceUuid: string;
}

const ErrorAlert: React.FC<Pick<AlertProps, 'children'>> = ({ children }) => {
  const message = typeof children === 'string' ? children : String(children);
  return (
    <Alert
      data-testid="alertError"
      type="error"
      dismissible={false}
      header={<span style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>Submit Error</span>}
    >
      <pre style={{ whiteSpace: 'pre-wrap' }}>{message}</pre>
    </Alert>
  );
};

const SuccessAlert: React.FC<Pick<AlertProps, 'children'>> = ({ children }) => (
  <Alert type="success" header="Submit Success" dismissible={false}>
    {children}
  </Alert>
);

export const InvoiceManualTranscriptionForm: React.FC<InvoiceManualTranscriptionFormProps> = (props) => {
  const { transcription, invoiceUuid } = props;

  const [submitError, setSubmitError] = React.useState<string>('');
  const [submitSuccess, setSubmitSuccess] = React.useState<string>('');

  const handleSubmitSuccess = (transcriptionUuid: string) => {
    setSubmitError('');
    setSubmitSuccess(
      'Transcription added! It has passed validation and is now processing.\n' +
        `Refresh shortly to view it with UUID: ${transcriptionUuid}.`
    );
  };

  const handleSubmitError = (error?: string) => {
    setSubmitSuccess('');
    setSubmitError(error ?? 'There was a problem submitting the form');
  };

  const {
    handleSubmit,
    handleReset,
    isSubmitDisabled,
    isSubmitting,
    topLevelFields,
    meterFields,
    utilityFields,
    topLevelErrorCount,
    meterErrorCount,
    utilityErrorCount,
    setTopLevelValue,
    setMeterValue,
    setUtilityValue,
    setTopLevelError,
    setMeterError,
    setUtilityError,
    addMeter,
    removeMeter,
    addUtility,
    removeUtility,
  } = useHydrateInvoiceManualTranscriptionForm({
    transcription,
    invoiceUuid,
    onSubmitSuccess: handleSubmitSuccess,
    onSubmitError: handleSubmitError,
  });

  return (
    <SpaceBetween size="xs">
      <form onSubmit={handleSubmit}>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button data-testid="reset" formAction="none" variant="normal" onClick={handleReset}>
                Reset
              </Button>

              <Button
                data-testid="submit"
                loading={isSubmitting}
                loadingText="Submitting invoice manual transcription"
                formAction="submit"
                disabled={isSubmitDisabled}
                variant="primary"
              >
                Submit
              </Button>
            </SpaceBetween>
          }
        >
          <FormSection headerText="Transcription Overview" errorCount={topLevelErrorCount}>
            <SpaceBetween size="xs">
              <InvoiceManualTranscriptionTopLevelFields
                topLevelFields={topLevelFields}
                onChange={setTopLevelValue}
                onValidate={setTopLevelError}
                isSubmitting={isSubmitting}
              />
            </SpaceBetween>
          </FormSection>

          <FormSection headerText="Meter Level Data" errorCount={meterErrorCount}>
            <SpaceBetween size="xs">
              <InvoiceManualTranscriptionMeterFields
                meters={meterFields}
                onAdd={addMeter}
                onRemove={removeMeter}
                onChange={setMeterValue}
                onValidate={setMeterError}
                isSubmitting={isSubmitting}
              />
            </SpaceBetween>
          </FormSection>
          <FormSection headerText="Utility Level Data" errorCount={utilityErrorCount}>
            <SpaceBetween size="xs">
              <InvoiceManualTranscriptionUtilityFields
                utilities={utilityFields}
                onAdd={addUtility}
                onRemove={removeUtility}
                onChange={setUtilityValue}
                onValidate={setUtilityError}
                isSubmitting={isSubmitting}
              />
            </SpaceBetween>
          </FormSection>
        </Form>
      </form>
      <div>
        {submitError && <ErrorAlert>{submitError}</ErrorAlert>}
        {submitSuccess && <SuccessAlert>{submitSuccess}</SuccessAlert>}
      </div>
    </SpaceBetween>
  );
};
