import { Alert, Box, ColumnLayout, Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import * as React from 'react';
import { useContext, useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import { InvoiceMetadata, Transcription } from '@amzn/taprootinvoicemanagementservice-ts-client';
import { reprocessInvoicesForBlueprint } from 'src/client/api-gateway';
import { AppContext } from '../App';
import { useFetchPdf } from '../common-components/hooks/useFetchPdf';
import { BreadcrumbGroupWithRouting } from '../breadcrumbs/BreadcrumbGroupWithRouting';
import { INVOICE_BREADCRUMBS, ROOT_BREADCRUMB } from '../breadcrumbs/breadcrumbs';
import { InvoiceTranscriptions } from './transcriptions/invoice-transcriptions';
import { SplitPanelContext } from 'src/components/split-panel/SplitPanelProvider';
import { InvoiceManualTranscriptionForm } from 'src/components/manifests/manual-transcription/InvoiceManualTranscriptionForm';
import { PDFViewer } from 'src/components/common-components/PDFViewer';
import { InvoiceManualTranscriptionSplitPanel } from 'src/components/manifests/manual-transcription/InvoiceManualTranscriptionSplitPanel';
import { useFetchTimsInvoiceMetadataByUuid } from 'src/components/manifests/hooks/useFetchTimsInvoiceMetadataByUuid';
import { formatTimestamp } from 'src/components/manifests/utils/timestampUtils';

export interface InvoiceDetailsParams {
  invoiceUuid: string;
}

const InvoiceMetadataTable = ({ invoiceMetadata }: { invoiceMetadata: InvoiceMetadata }) => (
  <SpaceBetween direction="vertical" size="l">
    <ColumnLayout columns={3} variant="text-grid">
      <div>
        <Box variant="small">Invoice UUID</Box>
        <div>{invoiceMetadata.invoiceUuid || '-'}</div>
      </div>

      <div>
        <Box variant="small">Invoice PDF Name</Box>
        <div>{invoiceMetadata.invoicePdfName || '-'}</div>
      </div>

      <div>
        <Box variant="small">Location Code</Box>
        <div>{invoiceMetadata.locationCode || '-'}</div>
      </div>

      <div>
        <Box variant="small">Artifact Id/Barcode</Box>
        <div>{invoiceMetadata.artifactId || '-'}</div>
      </div>

      <div>
        <Box variant="small">Supplier Id</Box>
        <div>{invoiceMetadata.supplierId || '-'}</div>
      </div>

      <div>
        <Box variant="small">Utility Type</Box>
        <div>{invoiceMetadata.utilityType || '-'}</div>
      </div>
    </ColumnLayout>
    <ColumnLayout columns={3} variant="text-grid">
      <div>
        <Box variant="small">Invoice Entity</Box>
        <div>{invoiceMetadata.invoiceEntity || '-'}</div>
      </div>

      <div>
        <Box variant="small">Invoice Type</Box>
        <div>{invoiceMetadata.invoiceType || '-'}</div>
      </div>

      <div>
        <Box variant="small">Invoice Source</Box>
        <div>{invoiceMetadata.invoiceSource || '-'}</div>
      </div>

      <div>
        <Box variant="small">Invoice Ingestion Timestamp (UTC)</Box>
        <div>{formatTimestamp(invoiceMetadata.ingestionTimestamp)}</div>
      </div>
    </ColumnLayout>
  </SpaceBetween>
);

export const InvoiceDetails = () => {
  const location = useLocation();
  const { invoiceUuid } = useParams<InvoiceDetailsParams>();
  const { setNotificationsItems } = React.useContext(AppContext);
  const { splitPanel, setSplitPanel, showSplitPanel } = useContext(SplitPanelContext);
  const { invoiceMetadata, invoiceMetadataLoading, fetchTimsInvoiceMetadataByUuid, invoiceMetadataError } =
    useFetchTimsInvoiceMetadataByUuid();
  const { pdf, fetchPdf, pdfErrorMessage, pdfLoading } = useFetchPdf();

  // Clean up notifications on unmount
  useEffect(
    () => () => {
      setNotificationsItems([]);
    },
    []
  );

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchTimsInvoiceMetadataByUuid(invoiceUuid);
    }
    return () => {
      isMounted = false;
    };
  }, [invoiceUuid, fetchTimsInvoiceMetadataByUuid]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchPdf(invoiceUuid);
      const default_result: Transcription = {
        accountNumber: '',
        supplierAddress: '',
        supplierName: '',
        billingStartDate: '',
        billingEndDate: '',
        invoiceDate: '',
        totalCharge: undefined,
        utilityActivities: [],
        meterActivities: [],
      };
      setSplitPanel(
        <InvoiceManualTranscriptionSplitPanel>
          <InvoiceManualTranscriptionForm invoiceUuid={invoiceUuid} transcription={default_result} />
        </InvoiceManualTranscriptionSplitPanel>
      );
      showSplitPanel(true);
    }
    return () => {
      isMounted = false;
      setSplitPanel(undefined);
      showSplitPanel(false);
    };
  }, [invoiceUuid, fetchPdf, invoiceMetadata, setSplitPanel, showSplitPanel]);

  function callReprocessInvoicesAPI() {
    setNotificationsItems([
      { type: 'info', loading: true, content: <>Attempting Reprocess for invoices : {invoiceUuid}</> },
    ]);
    reprocessInvoicesForBlueprint([invoiceUuid])
      .then((res) => {
        if (res['failedInvoiceIds'].length === 0) {
          setNotificationsItems([
            {
              type: 'success',
              content: `Successfully reprocessed ${invoiceUuid}`,
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setNotificationsItems([]),
            },
          ]);
        } else {
          setNotificationsItems([
            {
              type: 'error',
              content: `Failed to reprocess ${invoiceUuid}`,
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setNotificationsItems([]),
            },
          ]);
        }
      })
      .catch((e) =>
        setNotificationsItems([
          {
            type: 'error',
            content: `Failed to reprocess ${invoiceUuid}`,
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setNotificationsItems([]),
          },
        ])
      );
  }

  return (
    <SpaceBetween size="l">
      <BreadcrumbGroupWithRouting
        items={[
          {
            text: ROOT_BREADCRUMB.homepage.text,
            href: ROOT_BREADCRUMB.homepage.getHref(),
          },
          {
            text: INVOICE_BREADCRUMBS.invoiceDetails.text,
            href: INVOICE_BREADCRUMBS.invoiceDetails.getHref(invoiceUuid),
          },
        ]}
      />
      <Container header={<Header variant="h2">Invoice metadata</Header>}>
        <SpaceBetween size="xs">
          <Container>
            {invoiceMetadataLoading ? (
              <div className="banner">Loading invoice details...</div>
            ) : invoiceMetadataError ? (
              <Alert type="error" header="Error Fetching Metadata">
                {invoiceMetadataError}
              </Alert>
            ) : invoiceMetadata ? (
              <InvoiceMetadataTable invoiceMetadata={invoiceMetadata} />
            ) : (
              <div>No invoice details found.</div>
            )}
          </Container>
        </SpaceBetween>
      </Container>

      <Container header={<Header variant="h2">Invoice file</Header>}>
        <SpaceBetween size="xs">
          <Container>
            <PDFViewer file={pdf} />
          </Container>
        </SpaceBetween>
      </Container>
      <InvoiceTranscriptions />
    </SpaceBetween>
  );
};
